@tailwind base;
@tailwind components;
@tailwind utilities;


*{
  font-family: "Marhey", sans-serif;
  font-optical-sizing: auto;
  font-weight: 300;
  font-style: normal;
  text-align: center;
}

.footer {
  margin-top: 5rem;
}

.map { 
  margin-top: 2rem;
}